<template>
     <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 auth-con">
                <div class="logo-site">  <img src="/theme/img/logo-dark.svg"></div>
               <div class="authbox">
                   <h3>Forgot Password</h3>
                   <Form  id="forgetpassword" method="post" @submit="forgetPasspHandle" v-if="!fgPwd">
                       <div class="mb-3">
                            <label for="useremail" class="form-label">Email</label>
                            <Field ref="useremail"  type="email" class="form-control" id="useremail" name="useremail" placeholder="name@example.com" v-model="model.useremail" :rules="validateEmail">
                            </Field>
                            <ErrorMessage name="useremail" />
                        </div>  
                        <div class="mb-3">
                            <p v-html="model.status"></p>
                        </div>
                        <div class="mb-3">
                            <button class="btn" type="submit">Send Instructions</button>
                        </div>
                        <div class="mb-3 text-justify">
                            <a class="auth-nav" href="/auth/login">Login</a> 
                        </div>
                   </Form >
                   <div class=""  v-else >
                       <p v-html="model.status"></p>
                       <div class="mb-3">
                            <a class="btn" href="/auth/login">Go to Login</a>
                        </div>
                   </div>

               </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Form, Field ,ErrorMessage} from 'vee-validate';
import axios from 'axios'
export default {
    components: { Form, Field, ErrorMessage},
    data () {
        return {
            fgPwd:false,
            model: {
                useremail: '',
                status:''
            }
        }
    },
    mounted() {
        if(this.$refs.useremail) this.$refs.useremail.$el.focus()
    },
    methods: {
        forgetPasspHandle(values){
            var self=this;
            axios.post('/api/auth/recover', {
                email:values.useremail.toLowerCase(),
        }).then( async response => {
            self.fgPwd = true
            self.model.status = `<p>${response.data.message}</p>`;
           
        }).catch(error=>{
            self.model.status = error.response.data.message
        })},
        validateEmail(value) {
            if (!value) {   return 'Email is required';}
            var regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {   return 'Invalid email';}
            return true;
        },
    }
}
</script>